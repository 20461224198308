<template>
  <section class="infinite-scroll">
    <transition-group name="list" tag="p">
      <div v-for="item in items" :key="item[itemKey]" class="list-item">
        <slot name="item" v-bind:item="item" />
      </div>
    </transition-group>

    <div v-observe-visibility="handleScroll"></div>
  </section>
</template>

<script>
import Vue from 'vue'
Vue.directive('observe-visibility', ObserveVisibility)
import { ObserveVisibility } from 'vue-observe-visibility'

export default {
  name: 'InfiniteScroll',
  props: {
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    }
  },
  methods: {
    handleScroll(isVisible) {
      if (!isVisible) return
      this.$emit('refetch')
    }
  }
}
</script>

<style lang="scss">
// .list-leave-active
.list-enter-active {
  transition: all 1s;
}
.list-leave-active {
  transition: all 0.1s;
}
.list-leave-to {
  opacity: 0;
  background-color: rgba(var(--vs-warning), 1) !important;
  transform: translateX(-30px);
}
.list-enter {
  opacity: 0;
  background-color: rgba(var(--vs-warning), 1) !important;
  transform: translateY(30px);
}
</style>
